html {
    background-color: #ffffff;
    box-sizing: border-box;
    /* scroll-behavior: smooth; */
}

body {
    font-family: 'Raleway', sans-serif;
}

.body-wrapper {
    overflow: hidden;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

a * {
    pointer-events: none;
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}
@media (min-width: 1400px) {
    .container {
        max-width: 1200px;
    }
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}

h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 60px;
    padding-bottom: 42px;
    font-family: 'Roboto Serif';
    color: #fff;
    padding-top: 30px;
}

h1 b {
    font-size: 60px;
    font-weight: 800;
}

h1 span {
    display: inline-flex;
}

h2 {
    font-family: 'Roboto Serif';
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 60px;
    color: #142645;
    padding-top: 95px;
    padding-bottom: 25px;
}

h3 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    position: relative;
    padding-top: 60px;
    padding-bottom: 15px;
}

p {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
}

p:not(:last-child) {
    padding-bottom: 35px;
}

/* Banner */
.banner {
    background: #ffffff;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center;
    grid-template-columns: 0.8fr 2fr 0.7fr;
}
.banner .logo-box {
    background: #eaf2f8;
    border-radius: 10px;
}
.banner .logo-box a {
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    color: #090909;
    margin-bottom: 15px;
}
.banner .info {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7d7f82;
    position: relative;
    margin-left: 15px;
}
.banner .info:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #f27558;
}
.banner .domaner-btn {
    background: #f27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 10px;
    }
    .banner .info {
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
@media only screen and (max-width: 940px) {
    .banner {
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a {
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title {
        line-height: 24px;
        text-align: center;
    }
    .banner .info {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before {
        content: none;
    }
}
@media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
}

.container .domainer::before {
    content: none !important;
}

.site-header {
    position: relative;
}
.site-header::before {
    position: absolute;
    content: '';
    width: 50%;
    height: 100%;
    background: #427cec;
    right: 0;
    z-index: -1;
    top: 0;
}

.header-description {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #dce8fe;
}

.site-header .container {
    position: relative;
}

.site-header .container.hero::before {
    position: absolute;
    content: '';
    background-image: url(../image/women-heart.svg);
    width: 937px;
    height: 753px;
    background-size: contain;
    left: -530px;
    top: -100px;
    background-repeat: no-repeat;
}

.logo-wrapper {
    padding: 55px 0;
}

.site-logo {
    font-family: 'Roboto Serif';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 21px;
    color: #142645;
    position: relative;
    padding-left: 55px;
}

.site-logo::before {
    position: absolute;
    content: '';
    background-image: url(../image/logo.svg);
    width: 46px;
    height: 37px;
    background-size: contain;
    background-repeat: no-repeat;
    top: -10px;
    left: 0;
}

.nav-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 55px 0;
}

.nav-wrapper a {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #beccfa;
    transition: all 0.3s;
}

.nav-wrapper a:hover {
    color: #fff;
}

.inner {
    display: grid;
    grid-template-columns: 39% 1fr;
    position: relative;
}

.right-side {
    position: relative;
    padding-bottom: 90px;
}

.right-side::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #427cec;
    left: -70px;
    top: 0;
    z-index: -1;
}

.grey-bg {
    background-color: #f6f6f6;
}

.grey-section {
    position: relative;
}

.grey-section::before {
    position: absolute;
    content: '';
    width: 50%;
    height: 100%;
    background-color: #f6f6f6;
    z-index: -1;
}

.arrow {
    background-image: url(../image/down-arrow.svg);
    width: 15px;
    height: 30px;
    background-size: contain;
    background-repeat: no-repeat;
}

.what {
    margin-left: 25px;
    background-color: #fff;
    padding-left: 90px;
    padding-bottom: 50px;
    padding-right: 200px;
    position: relative;
}

.what::before {
    position: absolute;
    content: '';
    background-image: url(../image/men-heart.svg);
    width: 616px;
    height: 598px;
    background-size: contain;
    background-repeat: no-repeat;
    right: -394px;
    top: -57px;
}

.left-wrapper {
    padding: 0 66px 100px;
}

#what-is-blockchain::before {
    position: absolute;
    content: '';
    background-image: url(../image/blockchain.svg);
    width: 50px;
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    left: 0;
}

#nfts::before {
    position: absolute;
    content: '';
    background-image: url(../image/nft.svg);
    width: 66px;
    height: 66px;
    background-size: contain;
    background-repeat: no-repeat;
    left: 0;
}

#control-storage::before {
    position: absolute;
    content: '';
    background-image: url(../image/shield.svg);
    width: 60px;
    height: 60px;
    background-size: contain;
    background-repeat: no-repeat;
    left: 0;
}

#what-is-blockchain,
#nfts,
#control-storage {
    padding-left: 80px;
}

.border-grey {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 50px;
}

.liqudate {
    text-align: center;
}

.liqudate h2 {
    width: 60%;
    margin: 0 auto;
}

.donation {
    display: flex;
    padding-top: 100px;
    gap: 80px;
}

.donation1-content,
.donation2-content {
    width: 50%;
}

.donation1-content h3,
.donation2-content h3 {
    padding-top: 0;
    padding-bottom: 35px;
}

.donation1-content p {
    padding-bottom: 50px;
}

.donation1 {
    background-image: url(../image/donation1.svg);
    background-size: contain;
    width: 50%;
    background-repeat: no-repeat;
}

.donation2 {
    background-image: url(../image/donation2.svg);
    background-size: contain;
    width: 50%;
    background-repeat: no-repeat;
    background-position: center;
}

.donation a {
    font-weight: bold;
    text-decoration: underline;
    color: inherit;
}

/* FAQ  */

.text-center h2 {
    color: #000;
    font-weight: 300;
}

.faq {
    background: #f6f6f6;
    border-radius: 10px;
    margin-bottom: 20px;
}

.faq-wrapper {
    padding-bottom: 100px;
    position: relative;
    z-index: 2;
}
.faq-section-wrapper {
    padding-bottom: 90px;
}
/* .faq-heading {
    margin-bottom: 38px!important;
    padding-top: 68px!important
  } 
   */
.faq-wrapper {
    padding-top: 26px;
}
.center {
    padding-bottom: 25px;
}

.faq.expanded + .faq {
    border-top: 0;
}

.faq-title {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.faq-title h3 {
    color: #000;
    font-size: 28px;
    font-weight: 400;
    height: 98px;
    line-height: 98px;
    pointer-events: none;
    margin-bottom: 0 !important;
    margin-top: 0;
    padding: 0;
}
.faq-btn {
    align-items: center;
    border: 0;
    border-radius: 25px;
    display: flex;
    font-size: 100px;
    font-weight: 200;
    height: 49px;
    justify-content: center;
    width: 49px;
}

.faq-btn:hover,
.faq-title:hover {
    cursor: pointer;
}

.faq {
    transition: background-color 0.2s linear, font-weight 0.2s linear, border-radius 0.2s linear;
    position: relative;
    padding-bottom: 0;
    padding-left: 40px;
    padding-right: 40px;
}
.faq-background-outer {
    border: 1px solid #3b495f;
    position: absolute;
    border-radius: 40px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}
.faq-content {
    position: relative;
    z-index: 2;
}

.faq .faq-body {
    color: #3b495f;
    font-size: 17px;
    line-height: 24px;
    padding-bottom: 44px;
    padding-top: 0px;
    width: 95%;
}
.faq.collapsed .faq-body {
    display: none;
}
.faq-body a {
    font-weight: bold;
    color: #000;
    text-decoration: underline;
}

.faq.collapsed {
    padding: 20px 40px;
}

.faq.expanded {
    padding-top: 20px;
}

.faq.expanded h3 {
    color: #427cec;
}

.faq-btn span {
    pointer-events: none;
}

.faq.collapsed .faq-btn span {
    color: #427cec;
}

.faq.expanded .faq-btn span {
    color: #427cec;
}

.faq.collapsed .faq-btn span.plus,
.faq.expanded .faq-btn span.minus {
    display: block;
}

.faq.collapsed .faq-btn span.minus,
.faq.expanded .faq-btn span.plus {
    display: none;
}
.faq.expanded .faq-title h3 {
    font-weight: 700;
}
.text-center {
    text-align: center;
    color: #000;
}
.text-center p {
    padding-bottom: 50px;
    color: #000;
}
.faq-title h3 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 60px;
    display: flex;
    align-items: center;
}
.faq-body p {
    color: #000;
    text-align: left;
}
.faq-wrapper h2,
.center {
    text-align: center;
}

/* OUR TEAM  */

.our-team {
    text-align: center;
    color: #fff;
    background-color: #142645;
    padding-bottom: 300px;
}

.our-team h2,
.contact-us h2 {
    color: #fff;
}

.contact-us {
    background-color: #427cec;
    color: #ffff;
    text-align: center;
    margin-top: -200px;
    padding: 0 100px 100px;
}

.contact-us p {
    padding-bottom: 55px;
}

.cta-btn {
    background: #fff;
    border: 0;
    padding: 30px 80px;
    color: #f86533;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    cursor: pointer;
    position: relative;
    z-index: 2;
    margin-top: 1em;
}

footer .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
    .right-side::before {
        left: -42px;
    }
}

/* 1000px  */

@media only screen and (max-width: 991px) {
    /* .site-logo {
        padding-left: 0;
    }

    .site-logo::before {
        left: -50px;
    } */
    .right-side::before {
        left: -10px;
    }

    .what::before {
        right: -333px;
        top: -170px;
    }

    .site-header .container.hero::before {
        left: -575px;
    }

    .inner {
        grid-template-columns: 45% 1fr;
    }

    .donation {
        gap: 40px;
    }

    footer .site-logo::before {
        content: none;
    }
}

/* MOBILE  */

@media only screen and (max-width: 768px) {
    .container {
        padding: 0 22px;
    }
    h1 {
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        padding-bottom: 20px;
    }

    h1 b {
        font-weight: 800;
        font-size: 30px;
        line-height: 36px;
    }

    h2 {
        font-size: 26px;
        line-height: 30px;
        padding-top: 75px;
    }

    h3 {
        font-weight: 700;
        font-size: 26px;
        line-height: 30px;
        padding-top: 40px;
    }

    p {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
    }

    .nav-wrapper {
        display: none;
    }

    .site-header .container.hero::before,
    .site-header::before,
    .grey-section::before {
        content: none;
    }

    .inner {
        display: flex;
        flex-direction: column;
    }

    .right-side {
        padding-bottom: 125px;
    }

    .right-side::before {
        width: 300%;
        left: -200px;
    }

    .logo-wrapper {
        padding: 20px 0;
        display: flex;
        justify-content: center;
    }

    .what {
        padding-left: 20px;
        padding-right: 20px;
        margin-left: 0;
    }

    .what::before {
        width: 343.17px;
        height: 333.14px;
        right: 0;
        top: -180px;
        right: -143px;
    }

    .arrow {
        background-image: url(../image/arrow-mobile.svg);
        width: 100px;
        height: 53px;
        position: relative;
        z-index: 10;
    }

    .left-wrapper {
        padding: 0 0 65px;
        position: relative;
        z-index: 2;
        margin-top: -76px;
    }

    .left-wrapper::before {
        position: absolute;
        content: '';
        width: 200%;
        height: 100%;
        background-color: #f6f6f6;
        left: -200px;
        z-index: -1;
    }

    #what-is-blockchain,
    #nfts,
    #control-storage {
        padding-bottom: 30px;
    }

    #what-is-blockchain {
        padding-top: 60px;
    }

    #nfts::before {
        top: calc(50% - 30px);
    }

    #what-is-blockchain::before {
        top: calc(50% - 10px);
    }

    #control-storage::before {
        top: calc(50% - 25px);
    }

    .liqudate {
        padding-bottom: 30px;
    }

    .liqudate h2 {
        width: 100%;
        margin: 0 auto;
        text-align: left;
    }

    .liqudate p {
        text-align: left;
    }

    .donation1,
    .donation2 {
        background-image: none;
    }

    .donation.first {
        padding-top: 250px;
    }

    .donation.second {
        padding-top: 270px;
    }

    .donation.first::before {
        position: absolute;
        content: '';
        background-image: url(../image/donation1.svg);
        width: 250px;
        height: 250px;
        background-size: contain;
        background-repeat: no-repeat;
        top: 0;
        left: -62px;
    }
    .donation.second::before {
        position: absolute;
        content: '';
        background-image: url(../image/donation2.svg);
        width: 250px;
        height: 250px;
        background-size: contain;
        background-repeat: no-repeat;
        top: 0;
        left: -62px;
    }

    .donation {
        flex-direction: column;
        position: relative;
        gap: 0;
    }

    .donation1-content,
    .donation2-content {
        width: 100%;
    }

    .faq-title h3 {
        font-size: 20px;
        line-height: 28px;
    }

    .faq-btn {
        font-size: 75px;
    }

    .faq-title h3 {
        height: 55px;
    }

    .contact-us {
        padding: 0 50px 55px;
        margin-top: -250px;
    }

    #contacts {
        padding-top: 50px;
    }

    .cta-btn {
        padding: 25px 65px;
    }

    .cta-btn::before {
        top: -90px;
    }
    .cta-btn::after {
        top: -20px;
    }

    footer .site-logo {
        padding-left: 0;
    }

    footer .site-logo::before {
        content: none;
    }

    footer .container {
        flex-direction: column;
        padding: 25px 0;
    }
}

@media only screen and (max-width: 415px) {
    .cta-btn {
        padding: 25px 40px;
    }
    .contact-us {
        padding: 0 30px 55px;
    }
}
